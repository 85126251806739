import Network from '@/services/network'
import {useQuery} from '../utils/query'
import {useCreateFormData} from './form-data/bill-pay/useFormData'
import {ToWords} from "to-words";

export default function handlePurchaseAndSales() {
    const network = new Network;
    const {allParams,getQueryString} = useQuery(20)
    const {formData, salesFormData, prepareFormData, prepareSalesFormData} = useCreateFormData()

    const fetchCurrencyList = (query) => {
        return network.api('get', '/currency/all'+query);
    }
    const fetchProductList = (query) => {
        return network.api('get', '/inventory/products/all'+query);
    }
    const fetchProductsForSpecifications = (query) => {
        return network.api('get', '/inventory/products/for/specifications'+query);
    }
    const fetchProducts = (query) => {
        return network.api('get', '/inventory/products/list'+query);
    }
    const fetchAccountHead = (query) => {
        return network.api('get', '/account-heads'+query);
    }
    const fetchAccountPayable = (query) => {
        return network.api('get', '/inventory/accounts-payable'+query);
    }
    const fetchAccountReceivable = (query) => {
        return network.api('get', '/inventory/accounts-receivable'+query);
    }
    const fetchVatPayable = (query) => {
        return network.api('get', '/inventory/vat-payable'+query);
    }
    const updateBill = (purchaseMasterId, data) => {
        return network.api('put', `/inventory/purchases/bills/${purchaseMasterId}`, data);
    }
    const storeBill = (data) => {
        return network.api('post', '/inventory/purchases/bills', data);
    }
    const storeBillPayment = (data) => {
        return network.api('post', '/inventory/sales/invoices', data);
    }
    const fetchPurchaseList = (query) => {
        return network.api('get', `/inventory/purchases/bills${query ? query : getQueryString()}`);
    }
    const fetchSalesList = (query) => {
        return network.api('get', `/inventory/sales/invoices${query ?? getQueryString()}`);
    }
    const fetchSalesRegisterList = (query) => {
        return network.api('get', `/inventory/sales/sales-register` + query);
    }
    const fetchDueList = (query) => {
        return network.api('get', 'inventory/purchases/payment/due'+query);
    }
    const fetchInvoiceDueList = (query) => {
        return network.api('get', 'inventory/sales/receipt/due'+query);
    }
    const storePayment = (data) => {
        return network.api('post', 'inventory/purchases/payment/create', data)
    }
    const storeInvoice = (data) => {
        return network.api('post', 'inventory/sales/receipt/create', data)
    }
    const storeAdvanceInvoice = (data) => {
        return network.api('post', 'inventory/sales/receipt/advance', data)
    }

    const fetchPurchaseBill = (purchaseMasterId, query = '') => {
        return network.api('get', `/inventory/purchases/bills/${purchaseMasterId}`+query);
    }

    const fetchSalesBill = (salesId, query = '') => {
        return network.api('get', `/inventory/sales/invoices/${salesId}`+query);
    }

    const updateSalesBill = (salesId, data) => {
        return network.api('put', `/inventory/sales/invoices/${salesId}`, data);
    }
    const addPaymentKeyWithBill = (bills) => {
       return bills.map(bill => {
           bill.payment = ''
           return bill
       })
    }

    const fetchSinglePayment = (paymentId, query) => {
        return network.api('get', `/inventory/purchases/payment/${paymentId}` + query);
    }

    const updateBillPayment = (paymentMasterId, data) => {
        return network.api('put', `/inventory/purchases/payment/${paymentMasterId}`, data);
    }

    const fetchMoneyReceipt = (receiptId, query) => {
        return network.api('get', `/inventory/sales/receipt/${receiptId}` + query);
    }

    const updateMoneyReceipt = (receiptId, data) => {
        return network.api('put', `/inventory/sales/receipt/${receiptId}`, data);
    }

    const returnMoneyReceipt = (receiptId, data) => {
        return network.api('post', `/money-receipt/${receiptId}/return`, data);
    }

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-IN', {
            minimumFractionDigits: 2
        }).format(value);
    }

    const fetchProductStock = (productId, query) => {
        return network.api('get', `/inventory/products/${productId}/stock` + query);
    }

    const fetchBatchProductStock = (query) => {
        return network.api('get', `/inventory/products/stocks` + query);
    }

    const fetchSalesOrderList = (query) => {
        return network.api('get', `/inventory/sales/order${query}`)
    }


    const fetchOrderStatus = (query) => {
        return network.api('get', `/inventory/sales/order-status${query}`)
    }

    const createSalesOrder = (data) => {
        return network.api('post', `/inventory/sales/order`, data)
    }


    const updateSalesOrder = (id, data) => {
        return network.api('put', `/inventory/sales/order/${id}`, data)
    }

    const fetchSalesOrderById = (id, query) => {
        return network.api('get', `/inventory/sales/order/${id}${query}`)
    }

    const fetchSalesOrderTypes = (query) => {
        return network.api('get', `/inventory/sales/order/sale-types${query}`)
    }

    const fetchPendingProductInitiateList = (query) => {
        return network.api('get', `/inventory/sales/pending-project-initiate${query}`)
    }

    const createDeliveryChallan = (data) => {
        return network.api('post', `/inventory/delivery-challan`, data)
    }

    const updateDeliveryChallan = (id, data) => {
        return network.api('put', `/inventory/delivery-challan/${id}`, data)
    }

    const fetchDeliveryChallanList = (query) => {
        return network.api('get', `/inventory/delivery-challan${query}`)
    }

    const fetchDeliveryChallan = (id, query) => {
        return network.api('get', `/inventory/delivery-challan/${id}${query}`)
    }

    const createInvoiceFromChallan = (data) => {
        return network.api('post', `/inventory/delivery-challan/invoice`, data)
    }

    const getAccountHeadBySlag = (slag, query) => {
        return network.api('get', `/inventory/accounts-by-slag/${slag}${query}`)
    }

    const currencyToWord = (value) => {
        const toWords = new ToWords({
            localeCode: 'en-BD',
            converterOptions: {
                currency: true,
                ignoreDecimal: false,
                ignoreZeroCurrency: false,
                doNotAddOnly: false,
            }
        });
        return toWords.convert(value);
    }

    const fetchSalesCommissionReportSummary = (query) => {
        return network.api('get', `/reports/sales-commission/summery${query}`)
    }

    const fetchSalesCommissionReportDetails = (query) => {
        return network.api('get', `/reports/sales-commission${query}`)
    }

    const fetchProductWithBatch = (query) => {
        return network.api('get', `/inventory/stock-register/product-list${query}`)
    }

    const fetchProductByStockId = (stockId, query) => {
        return network.api('get', `/inventory/stock-register/product-by-stock/${stockId}${query}`)
    }

    const fetchSalesOfferSetup = (query) => {
        return network.api('get', `/inventory/sales-offer-setup${query}`)
    }

    const storeSalesOfferSetup = (data) => {
        return network.api('post', '/inventory/sales-offer-setup', data);
    }

    const updateSalesOfferSetup = (itemId, data) => {
        return network.api('put', `/inventory/sales-offer-setup/${itemId}`, data);
    }

    const deleteSalesOfferSetup = (itemId, query) => {
        return network.api('delete', `/inventory/sales-offer-setup/${itemId}${query}`);
    }

    const fetchFreeQuantity = (productId, query) => {
        return network.api('get', `/inventory/sales-offer-setup/${productId}/get-free-quantity${query}`);
    }

    const fetchProductCostingList = (query) => {
        return network.api('get', `/inventory/product-costing${query}`)
    }
    const fetchProductCosting = (query) => {
        return network.api('get', '/inventory/product-costing/get-list' + query)
    }
    const storeProductCosting = (data) => {
        return network.api('post', '/inventory/product-costing', data);
    }
    const fetchSingleProductCosting = (masterId, query) => {
        return network.api('get', '/inventory/product-costing/' + masterId + query);
    }

    const fetchProductionList = (query) => {
        return network.api('get', `/inventory/productions${query}`)
    }
    const storeProduction = (data) => {
        return network.api('post', '/inventory/productions', data);
    }

    return {
        storeBill,
        fetchProductList,
        fetchProductsForSpecifications,
        fetchProducts,
        fetchAccountHead,
        fetchAccountPayable,
        fetchAccountReceivable,
        fetchSalesOrderList,
        fetchOrderStatus,
        createSalesOrder,
        fetchPendingProductInitiateList,
        fetchVatPayable,
        storeBillPayment,
        fetchSalesList,
        fetchSalesRegisterList,
        fetchPurchaseList,
        fetchDueList,
        fetchInvoiceDueList,
        formData,
        salesFormData,
        storePayment,
        storeInvoice,
        storeAdvanceInvoice,
        prepareFormData,
        prepareSalesFormData,
        addPaymentKeyWithBill,
        allParams,
        fetchPurchaseBill,
        updateBill,
        fetchSinglePayment,
        updateBillPayment,
        fetchMoneyReceipt,
        updateMoneyReceipt,
        returnMoneyReceipt,
        fetchSalesBill,
        updateSalesBill,
        formatCurrency,
        currencyToWord,
        fetchProductStock,
        fetchCurrencyList,
        updateSalesOrder,
        fetchSalesOrderById,
        fetchSalesOrderTypes,
        fetchProductByStockId,
        createInvoiceFromChallan,
        fetchDeliveryChallanList,
        createDeliveryChallan,
        updateDeliveryChallan,
        getAccountHeadBySlag,
        fetchDeliveryChallan,
        fetchProductWithBatch,
        fetchSalesCommissionReportSummary,
        fetchSalesCommissionReportDetails,
        fetchSalesOfferSetup,
        storeSalesOfferSetup,
        updateSalesOfferSetup,
        deleteSalesOfferSetup,
        fetchFreeQuantity,
        fetchProductCostingList,
        fetchProductCosting,
        storeProductCosting,
        fetchSingleProductCosting,
        storeProduction,
        fetchProductionList,
        fetchBatchProductStock
    }
}
